$primary: #5ABF01;
$border-radius: 8px;
$font-size-sm: .9375rem;

$gold: #FFD500;

// BOOTSTRAP
@import "../node_modules/bootstrap/scss/bootstrap";
// PRETTY CHECKBOX
@import "../node_modules/pretty-checkbox/src/pretty-checkbox";
// GLIDE JS
@import "../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";
@import "../node_modules/@glidejs/glide/src/assets/sass/glide.theme.scss";

@import "../styles/scss/variables";
@import "../styles/scss/fonts-gt";

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
body {
    // font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-family: 'gt-walsheim-regular', sans-serif;
    font-weight: normal;
    line-height: 1.5;
    font-size: 1.2rem;
    color: $black;
    @media screen and (max-width: 767px) {
        font-size: 1.1rem;
        line-height: 1.4;
    }
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'gt-walsheim-bold', sans-serif;
    font-weight: normal;
}
h6 {
    font-size: 1.1rem;
}
p {
    font-family: 'gt-walsheim-light', sans-serif;
    font-weight: normal;
    line-height: 1.3;
}
ul {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    line-height: 1.7;
}
// COLOR
.color-gold {
    color: $gold;
}
.bg-gold {
    background-color: $gold;
}
.bg-light-2 {
    background-color: $gray-300;
}
.bg-lokersolo {
    background-color: #DEFFCF;
}
// TEXT
.text-sm {
    font-size: .875rem;
}
.text-base {
    font-family: 'gt-walsheim-light', sans-serif;
}
.font-normal {
    font-family: 'gt-walsheim-regular', sans-serif;
}
.text-medium {
    font-family: 'gt-walsheim-medium', sans-serif;
}
.text-bold {
    font-family: 'gt-walsheim-bold', sans-serif;
}
.font-weight-bold {
    font-family: 'gt-walsheim-bold', sans-serif;
}
// CONTAINER
.container-sm {
    max-width: 976px;
}
.container {
    @media screen and (max-width: 577px) {
        max-width: 100%;
    }
}
//LIST
.list-normal {
    margin: 0;
    padding: 0;
    &.list-number {
        margin-left: 1rem;
        li {
            list-style: decimal;
        }
    }
    @media screen and (max-width: 767px) {
        &.list-number {
            li {
                margin-bottom: 12px;
            }
        }
    }
}
// FORM CONTROL
.form-control {
    border-radius: $border-radius;
    height: 42px;
    background: $gray-200;
    border-color: $gray-200;
    transition: $transition-base;
    &:focus {
        border-color: $primary;
        background: $gray-200;
    }
}
.input-group-2 {
    .form-control {
        border-color: $gray-400;
        background: $gray-100;
    }
}
// MODAL
.modal-content {
    border-radius: $border-radius;
}
// BADGE 
.badge {
    border-radius: 4px;
    font-weight: normal;
}
.hide-desktop {
    display: none!important;
    &.btn-cta-mobile {
        @media screen and (max-width: 991px) {
            display: inline-flex!important;
         }
    }
}
// BUTTON
.btn {
    font-weight: normal;
    font-size: $font-size-base;
    border-radius: $border-radius;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'gt-walsheim-regular', sans-serif;
}
.btn-inline-flex {
    display: inline-flex;
}
.btn-lg {
    font-size: 1.1rem;
    height: 56px;
}
.btn-gray {
    background-color: $gray-600;
    color: $white;
    border-color: $gray-600;
    &:hover {
        background-color: $gray-700;
        border-color: $gray-700;
        color: $white;
    }
}
.btn-neutral {
    background-color: $white;
    color: $black;
    border-color: $gray-300;
    &:hover {
        background-color: $gray-200;
        color: $black;
        border-color: $gray-300;
    }
}
.btn {
    &.btn-link {
        padding: 0px!important;
        height: auto!important;
        &.btn-link-muted {
            color: $gray-600!important;
        }
    }
}
.btn-whatsapp {
    background-color: #4AC959;
    border-color: #4AC959;
    color: $white;
    &:hover {
        color: $white; 
    }
}
.btn-outline-whatsapp {
    color: #4AC959;
    border-color: #4AC959;
}
// CARD
.card {
    border-radius: $border-radius;
    .card-header {
        background: $white;
        border-bottom: 0px;
        font-weight: 500;
        padding: 1rem;
        padding-bottom: 0px;
    }
    .card-body {
        padding: 1rem;
        &.card-body-flex,
        &.card-body-flex-h {
            display: flex;
            flex-direction: column;
        }
        &.card-body-flex-h {
            flex-direction: row;
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
        }
    }
    .card-footer {
        padding: 1rem;
        background: $white;
        border-top: 0px;
    }
}
.card-loker-top {
    flex: 1 0 auto;
    &.card-loker-top-fixed {
        width: 100%;
        box-sizing: border-box;
        // @media screen and (max-width: 1199px) {
        //     width: 334px;
        // }
        // @media screen and (max-width: 767px) {
        //     width: 100%;
        // }
    }
}
.div-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.card-loker-bottom {
    h6 {
        font-family: 'gt-walsheim-medium', sans-serif;
    }
}
.card-list {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    &.card-list-25 {
        .card-item {
            width: 25%;
        }
    }
    &.card-list-100 {
        .card-item {
            width: 100%;
        }
    }
    
}
.card-item {
    width: 100%;
    display: flex;
    height: 100%;
    .card {
        width: 100%;
    }
}
.card-title-featured {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
a {
    &.card-item {
        text-decoration: none;
        color: $black;
        &:hover {
            text-decoration: none;
        }
    }
}
.card-loker-img,
.card-loker-img-h {
    width: 100%;
    @include full-background;
    background-color: $gray-200;
    padding-top: 46%;
}
.card-loker-img-h {
    padding-top: 18%;
    width: 230px;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding-top: 43%;
        margin-bottom: 15px;
    }
}
.card-cursor {
    cursor: pointer;
}
.card-select-platform {
    transition: $transition-base;
    &:hover {
        background: $primary;
        color: $white;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .fab, .far, .fas {
            font-size: 2.2rem;
            margin-top: 8px;
            margin-bottom: 10px;
        }
        .sub {
            font-size: $font-size-sm;
        }
    }
}
// CENTERED ALL
.centered-all {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @media screen and (max-width: 767px) {
        display: block;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        padding-top: 2rem;
    }
}
// DROPDOWN
.dropdown-menu {
    overflow: hidden;
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .2s ease-in-out;
    transform: translateY(5px);
    border: 0px;
    border-radius: $border-radius;
    &.show {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(1px);
    }
    @media screen and (max-width: 991px) {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(1px);
    }
}
.dropdown-menu-lg {
    padding: 10px 14px;
    // box-shadow: 0 1.5rem 4rem rgba(22,28,45,.15);
    @media screen and (max-width: 991px) {
        padding: 0px;
        background: $primary;
        border-radius: 0px;
        box-shadow: none!important;
        padding-left: 14px;
        margin-bottom: 12px;
    }
}
.dropdown-item {
    padding: 5px 0px;
    white-space: nowrap;
    transition: $transition-base;
    font-size: $font-size-sm;
    &:hover {
        background: transparent;
    }
    @media screen and (max-width: 991px) {
        padding: 8px 0px;
        color: $white;
        &:hover {
            background: transparent;
        }
    }
}
.navbar-collapse-header {
    @media screen and (min-width: 992px) {
        display: none;
    }
    display: flex;
}
.navbar-light {
    .navbar-toggler {
        @media screen and (max-width: 991px) {
            color: $white;
            border-color: $white;
            background-color: $white;
        }
    }
}
.navbar-toggler-inside {
    float: right;
}
.link {
    text-decoration: underline;
}
.btn-cta-nav {
    @media screen and (max-width: 991px) {
        background: $white!important;
        color: $black!important;
        font-size: 1.1rem;
        height: 56px;
    }
}
// Navbar Dinsos
.navbar-lokersolo {
    background: #DEFFCF;
    height: 73px;
    padding-top: 0px;
    padding-bottom: 0px;
    .navbar-nav {
        .nav-item {
            margin-right: 18px;
            .nav-link {
                font-size: $font-size-base;
                font-weight: bold;
                transition: $transition-base;
                &.dropdown-toggle {
                    &:after {
                        margin-left: 8px;
                        color: $gray-500;
                    }
                }
                
            }
        }
        @media screen and (max-width: 991px) {
            .nav-item {
                margin-bottom: 8px;
                .nav-link {
                    font-size: 1.2rem;
                    font-weight: 600;
                    &.dropdown-toggle {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            .nav-item {
                margin-right: 0px;
            }
        }
    }
}
.navbar-brand {
    img {
        height: 24px;
    }
}
// Custom Navbar Collaspe
.navbar-collapse {
    @media screen and (max-width: 991px) {
        padding: 18px;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: auto!important;
        background:$primary!important;
        border-radius: 0px;
        box-shadow: $box-shadow;
        overflow-y: auto;
        z-index: 999;
        transition: $transition-base;
        transition-property: opacity, transform;
        &.collapsing {
            opacity: 0;
            transform: scale(0.9);
        }
        &.show {
            opacity: 1;
            visibility: visible;
        }
        .nav-link {
            color: $white!important;
        }
    }
}
//
.logo-dinsos-baru {
    width: 47px;
}
// GLIDE CUSTOM
.glide__full-height {
    .glide__track {
        .glide__slides {
            .glide__slide {
                height: auto!important;
            }
        }
    }
}
.glide__front {
    .glide__track {
        .glide__slides {
            padding: 24px 0px;
        }
    }
}
.glide-sidebanner {
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
}
// STYLE
.welcome {
    width: 100%;
    display: block;
    padding-top: 100px;
    padding-bottom: 48px;
    margin-bottom: 48px;
    @media screen and (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}
.welcome-title {
    font-size: 5rem;
    line-height: 1.1;
    @media screen and (max-width: 767px) {
        font-size: 3rem;
    }
}
.show-detail-hover {
    position: relative;
    &:after {
        transition: $transition-base;
        content:'Lihat Detail';
        margin-left: 8px;
        font-size: .7rem;
        text-transform: uppercase;
        vertical-align: middle;
        color: $primary;
        opacity: 0;
        line-height: 23px;
    }
}
.card {
    &:hover {
        .show-detail-hover {
            &:after {
                opacity: 1;
            }
        }
    }
}
.list-selected-filter {
    margin: 0;
    padding: 0;
    display: block;
    .selected-item {
        list-style: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: $font-size-sm;
        .fas,
        .far {
            color: $gray-500;
            margin-right: 6px;
        }
        &:hover {
            .fas,
            .far {
                color: $primary;
            }
        }
    }
    &.filtering {
        display: flex;
        flex-wrap: wrap;
        .selected-item {
            width: 33.3333333333%;
            font-size: $font-size-base;
            @media screen and (max-width: 991px) {
                width: 50%;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
        &.filtering-full {
            .selected-item {
                width:100%!important;
            }
        }
    }
    
}

// PRETTY
.pretty.p-toggle .state.p-success-o label:before, .pretty input:checked~.state.p-success-o label:before {
    border-color: $primary;
}
.pretty.p-default:not(.p-fill) input:checked~.state.p-success-o label:after {
    background-color: $primary!important;
}

// RINGKASAN
.list-col {
    display: flex;
    width: 100%;
    height: auto;
    flex-flow: wrap;
    position: relative;
    line-height: 32px;
    border:0;
    margin:0;
    padding:0;
    font-family: 'gt-walsheim-light',sans-serif;
    li {
        list-style: none;
        &:nth-child(2n+1) {
            width: 35%;
            clear: both;
            .fas {
                width: 32px;
                text-align: center;
            }
        }
        &:nth-child(2n+2) {
            width: 65%;
            padding-left: 8px;
        }
        span {
            float: right;
        }
    }
    @media screen and (max-width: 767px) {
        li {
            &:nth-child(2n+1) {
                width: 100%;
                .fas {
                    width: 26px;
                }
            }
            &:nth-child(2n+2) {
                width: 100%;
                padding-left: 0px;
                margin-bottom: 14px;
            }
            span {
                float: inherit;
            }
        }
    }
}
.list-lowongan {
    .card {
        color: $black;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
}

// Lowongan Detail Header
.lowongan-detail-header {
    @media screen and (max-width: 767px) {
        flex-direction: column;
        .lowongan-detail-header-1 {
            order: 2;
        }
        .lowongan-detail-header-2 {
            margin-bottom: 24px;
        }
    }
}
.lowongan-content {
    @media screen and (max-width: 767px) {
        padding: 0px!important;
        background-color: $white!important;
        border-radius: 0px!important;
    }
}

// CARD CLEAR
.card-clear-mobile {
    @media screen and (max-width: 767px) {
        box-shadow: none!important;
        border-radius: 0px!important;
        .card-body {
            padding: 0px!important;
        }
    }
}

// THANKS
.thanks-content {
    @media screen and (max-width: 767px) {
        font-size: 1.3rem;
    }
}

.input-gaji {
    flex-direction: column;
    .form-control {
        border-radius: $border-radius!important;
        &:first-child {
            margin-bottom: 8px;
        }
    }
}

// Datepicker Edit
.datepicker-top-left {
    &:before, &:after {
        display: none;
    }
}
.datepicker-dropdown {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.datepicker-container {
    width: 386px;
    font-size: 1rem;
}
.datepicker-panel>ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px;
}
.datepicker-panel>ul>li {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    width: 50px;
    height: 50px;
    &:hover {
        background-color: $gray-200;
    }
    &.picked {
        &:hover {
            color: $white;
        }
    }
}
.datepicker-panel>ul>li.picked {
    background-color: $primary;
    color: $white;
}
.datepicker-panel>ul>li.highlighted {
    background-color: $gray-300;
    color: $black;
}
.datepicker-panel {
    ul {
        li {
            &.highlighted {
                &.picked {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}