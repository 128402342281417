@font-face {
    font-family: 'gt-walsheim-light';
    src: url('../fonts/gt-walsheim-light-web.eot');
    src: url('../fonts/gt-walsheim-light-web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gt-walsheim-light-web.woff') format('woff'),
         url('../fonts/gt-walsheim-light-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'gt-walsheim-regular';
    src: url('../fonts/gt-walsheim-web.eot');
    src: url('../fonts/gt-walsheim-web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gt-walsheim-web.woff') format('woff'),
         url('../fonts/gt-walsheim-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'gt-walsheim-regular-italic';
    src: url('../fonts/gt-walsheim-regular-oblique-web.eot');
    src: url('../fonts/gt-walsheim-regular-oblique-web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gt-walsheim-regular-oblique-web.woff') format('woff'),
         url('../fonts/gt-walsheim-regular-oblique-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'gt-walsheim-medium';
    src: url('../fonts/gt-walsheim-medium-web.eot');
    src: url('../fonts/gt-walsheim-medium-web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gt-walsheim-medium-web.woff') format('woff'),
         url('../fonts/gt-walsheim-medium-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'gt-walsheim-bold';
    src: url('../fonts/gt-walsheim-bold-web.eot');
    src: url('../fonts/gt-walsheim-bold-web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gt-walsheim-bold-web.woff') format('woff'),
         url('../fonts/gt-walsheim-bold-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
 